@import "@vectara/vectara-ui/lib/styles/index";
@import "@vectara/vectara-ui/lib/sassUtils/index";
@import "./vars";

// TODO: Migrate to VUI.
.vuiCode {
  word-wrap: break-word !important;
  white-space: pre-wrap !important;
}

// Original line-height increases the height of the child,
// which messes up alignment in flex containers.
.normalizePopover {
  .bp4-popover-target,
  .bp4-popover-wrapper {
    line-height: 0;
  }
}

.bp4-transition-container {
  // Fixes a bug in which it's transparent.
  background-color: #fff;
}

.bp4-toast-container-top {
  z-index: 55 !important;
}

input[type="password"]:focus {
  outline: transparent;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  /* disable spinners on number fields */
  -webkit-appearance: none;
  -moz-appearance: textfield;
}

.signUpForm__countryMenu .bp4-menu-item:focus {
  outline: rgba(45, 114, 210, 0.6) solid 2px !important;
}

.popover-dark .bp4-popover-content {
  background-color: black;
}
.popover-dark .bp4-popover-arrow-fill {
  fill: black;
}

// Position feedback text inside Blueprint inputs.
.bp4-input-group .bp4-input-action {
  top: 6px;
  right: 6px;
}

.authLoader {
  height: 100vh;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-justify-between {
  justify-content: space-between;
}

.notification {
  background-color: transparent !important;
  background-image: none !important;
  box-shadow: none !important;
  color: #d1d5db !important;
}

.notification:hover {
  color: #ffffff !important;
}

.bp4-popover2-arrow {
  display: none !important;
}

// Enable VUI popovers inside of Blueprint modals.
.vuiPopover {
  z-index: 40 !important;
}

// Match Blueprint modals with VUI modals.
.bp4-dialog {
  background-color: #fff !important;
}

// TODO: Extract to VUI.
.vuiColumn {
  max-width: 500px;
}

.vuiColumnWide {
  max-width: 680px;
}

.textArrow {
  font-size: 1.6em;
  line-height: 0;
}

.loggedOutContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: $colorDarkerShade;
  justify-content: center;
  align-items: center;
}

@media (max-width: 530px) {
  .loggedOutContainer {
    padding: $sizeS;
  }
}

.loggedOutContainer__inner {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.loginPage {
  align-self: stretch;
}

.loggedOutPageForm {
  max-width: 480px;
  margin: 0 auto;
}

.loginFormBody {
  width: 100%;
}

.loggedOutFormHeader {
  width: 100%;
}

.loggedInAppLayout {
  height: 100vh;
  flex-direction: column;
  display: flex;
  padding-left: 0;
  transition: padding-left 0.1s ease-out;

  .vuiAppHeader {
    width: auto;
    left: 0;
    right: 0;
    transition: left 0.1s ease-out;
  }

  .vuiAppLayout {
    transition: padding-top 0.1s ease-out;
  }
}

.loggedInAppLayout-hasNav {
  padding-left: $appNavWidth;

  .vuiAppHeader {
    left: $appNavWidth;
    padding-left: $sizeS;
  }
}

.loggedInAppLayout-isWizardActive {
  padding-left: 0;

  .vuiAppLayout {
    padding-top: 0;
  }
}

.loggedOutFormLogo {
  height: 28px;
}
